// File app.js

// Require jquery, fixing error jquery/$ not defined
window.$ = window.jQuery = require('jquery');
require('slick-carousel');
require('@fancyapps/fancybox');

// Slick Slider settings
$('#sliderAlbumDetail').slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  centerMode: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

// Slider Marchandise
 $('.slider-marchandise').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  asNavFor: '.slider-marchandise-nav'
});
$('.slider-marchandise-nav').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '.slider-marchandise',
  arrows: false,
  dots: true,
  centerMode: true,
  focusOnSelect: true
});

// Fancy Box Gallery
$(".fancybox").fancybox();
